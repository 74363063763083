h3 {
    font-size: 3rem;
}

p {
    font-size: 1.2rem;
}

.main {
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
    background-color: rgba(255, 255, 255, 0.8);
    width: 70%;
    padding: 2%;
}

.home-img {
    width: 400px;
    height: 300px;
}

.text {
    padding: 20px 0 0 25px;
    line-height: 2;
    width: 45%;
}

.customerName {
    color: #55B0FF;
}

@media (max-width: 1200px) {
    .main {
        margin: auto;
    }

    .home-img {
        margin: auto;
    }

    .text {
        text-align: center;
        margin: auto;
        width: 60%;
    }
}
